import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";



export default function About() {

  const [first,setFirst] = useState(true);
  const [dash, setDash] = useState(false);
  const [profile,setProfile] = useState(false);
  const [setting,setSetting] = useState(false);
  const [contact,setContact] = useState(false);
  const [create,setCreate] = useState(false);
  return (
    <>
      <IndexNavbar fixed />
     <section className="   pt-6  md:py-20">
      
         <h6 className="text-blueMagenta  pb-4 text-center md:text-5xl text-4xl font-extrabold">
           ABOUT US
         </h6>
         <h6 className="md:font-semibold md:px-60  px-4  pb-6 md:text-xl text-darkSlate">
        24/7 Rescue Link™ is founded by the CEO <b>Thinagaran Raman</b> . It’s Malaysia based company where we have launched our 
        new technology mobile application or online platform which cover West Malaysia,East Malaysia,Singapore and Thailand 
        connecting to the nearby rescue team during emergency or breakdown situation. We are available at 24/7 to provide 
        services as the No.1 Service Provider for all technical solutions on-road and off- road.24/7 Rescue Link™ hereby to 
        create a <b>“Internet + Breakdown Assistance”</b> concept to modernize the breakdown assistance platform In South East Asia.
        </h6>
        
     </section>

     <section className=" md:py-20 py-6 md:px-60 bg-blueMagenta" >
          <div className="w-full lg:w-12/12 sm:w-12/12 ">
            <div className="flex sm:pl-0 md:px-40 ">
              <section className="container mx-auto">
        <div className="mb-4  mt-4 text-center justify-center">
          <ul className="flex  text-sm font-medium text-center px-2" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
            <li className="mr-2 " role="presentation ">
              <button className="inline-block active:bg-indigo-600 md:px-16 p-2 rounded border border-yellow-700 text-white " id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>

              <h6 className="text-xl font-bold">OUR VISION</h6>
            </button>
            </li>
            <li className="mr-2 " role="presentation">
              <button className="inline-block p-2 active:bg-indigo-600 md:px-16 rounded border  text-white border-yellow-700" id="dashboard-tab" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false" onClick={() => {setDash(true); setContact(false); setProfile(false); setCreate(false); setSetting(false); setFirst(false);}}>

              <h6 className="text-xl  font-bold">OUR MISSION</h6>
              </button>
            </li>
            <li className="mr-2" role="presentation">
              <button className="inline-block  active:bg-indigo-600 p-2 md:px-16 rounded border text-white border-yellow-700 " id="settings-tab" data-tabs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false" onClick={() => {setDash(false); setContact(false); setProfile(false);setCreate(false); setSetting(true); setFirst(false);}}>

              <h6 className="text-xl font-bold">OUR GOALS</h6>
              </button>
            </li>

            
          </ul>
        </div>

        <div id="myTabContent">
          <div className=
          {
            "p-4 rounded-lg dark:bg-gray-800 " +
            (first ? null : "hidden ")+
            (profile ? null : "hidden")
          } id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <ul className="flex flex-wrap  text-sm font-medium " id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
               <li className="rounded  py-2" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
               <h6 className="md:text-xl text-base text-white font-normal">Our vision is to create a better and peacefull travel for our golden customer with new technology and innovation.</h6>
                </li>
              </ul>
            </div>

                  {/*Second Tab */}

          <div className=
          {
            "p-4  rounded-lg dark:bg-gray-800 " +
            (dash ? null : "hidden")
          } id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
              <ul className="flex flex-wrap  text-sm font-medium " id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
               <li className="rounded-t-lg  px-2 py-2" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
               
              <h6 className="md:text-xl text-base text-white font-normal">Our new technology and innovation to solve all technical problems during emergency or breakdown situation and save the customer,properties and environment.</h6>
         
                </li>
              </ul>
          </div>


                                {/* third services */}
          <div className=
          {
            "p-4  rounded-lg dark:bg-gray-800 " +
            (setting ? null : "hidden")
          }
           id="settings" role="tabpanel" aria-labelledby="settings-tab">
             <ul className="flex flex-wrap text-sm font-medium" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
               <li className="rounded  px-2 " id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
              <h6 className="md:text-xl text-base text-white font-normal">1) To give solution for the impossible errors during breakdown or emergency situation.</h6>
                </li>
                <li className="rounded-t-lg  px-2 " id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
              <h6 className="md:text-xl text-base text-white font-normal">2) To improve the quality of fleet life.</h6>
                </li></ul>
                <ul>

                <li className="rounded text-white  px-2 " id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
              <h6 className="md:text-xl text-base font-normal">3) Control the breakdowns by using new technology.</h6>
                </li>

                <li className="rounded text-white px-2 " id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
              <h6 className="md:text-xl text-base font-normal">4)  Travel anywhere,anytime without any worries.</h6>
                </li>

                <li className="rounded-t-lg  px-2 " id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
              <h6 className="md:text-xl text-base text-white font-normal">5) To create smart service with new technology.</h6>
                </li>

                </ul>
            </div>

                              {/* Fourth Service*/}

          <div className=
          {
            "p-4 bg-gray-50 rounded-lg dark:bg-gray-800 " +
            (contact ? null : "hidden")
          } id="contacts" role="tabpanel" aria-labelledby="contacts-tab">
            <ul className="flex flex-wrap lg:px-107 text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
               <li className="rounded-t-lg  px-2 py-2">
               <button className="inline-block  p-4 rounded-t-lg border text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
                <img
              alt="..."
              className=" font-extrabold w-78  p-2 "
              src={require("assets/img/servicebreak.png").default}/>
              <h6 className="text-sm font-bold">Quality and<br></br>Re-conditioned Parts</h6>
            </button>
                </li>

                <li className="rounded-t-lg  px-2 py-2">
                <button className="inline-block  lg:px-105 p-4 rounded-t-lg border text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
                <img
              alt="..."
              className=" font-extrabold w-78  p-2 "
              src={require("assets/img/servicebreak.png").default}/>
              <h6 className="text-sm font-bold">Vehicle<br></br>Trading</h6>
            </button>
                </li>

                <li className="rounded-t-lg  px-2 py-2">
                 <button className="inline-block  lg:px-105 p-4 rounded-t-lg border text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
                <img
              alt="..."
              className=" font-extrabold w-78  p-2 "
              src={require("assets/img/servicebreak.png").default}/>
              <h6 className="text-sm font-bold">Purchasing made<br></br>Easy</h6>
            </button>
                </li>

                <li className="rounded-t-lg  px-2 py-2">
                <button className="inline-block  lg:px-105 p-4 rounded-t-lg border text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
                <img
              alt="..."
              className=" font-extrabold w-77  p-2 "
              src={require("assets/img/servicebreak.png").default}/>
              <h6 className="text-sm font-bold">Delivery<br></br>Services</h6>
            </button>
                </li>

                <li className="rounded-t-lg  px-2 py-2">
                <button className="inline-block  lg:px-105 p-4 rounded-t-lg border text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
                <img
              alt="..."
              className=" font-extrabold w-78  p-2 "
              src={require("assets/img/servicebreak.png").default}/>
              <h6 className="text-sm font-bold">Warranty<br></br>Services</h6>
            </button>
                </li>
              </ul>
             </div>
          
                        {/* Fifth Service*/}


          <div className=
          {
            "p-4 bg-gray-50 rounded-lg dark:bg-gray-800 " +
            (create ? null : "hidden")
          } id="create" role="tabpanel" aria-labelledby="create-tab">
            <ul className="flex flex-wrap lg:px-107 text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
               <li className="rounded-t-lg  px-2 py-2">
               <button className="inline-block  lg:px-105 p-4 rounded-t-lg border text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
                <img
              alt="..."
              className=" font-extrabold w-78  p-2 lg:ml-1 "
              src={require("assets/img/servicebreak.png").default}/>
              <h6 className="text-sm font-bold">Driverand<br></br>Fleet Health</h6>
            </button>
                </li>

                <li className="rounded-t-lg  px-2 py-2">
                <button className="inline-block  lg:px-105 p-4 rounded-t-lg border text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
                <img
              alt="..."
              className=" font-extrabold w-78 p-2 lg:ml-1"
              src={require("assets/img/servicebreak.png").default}/>
              <h6 className="text-sm font-bold">Vehicle service<br></br>fault reminder</h6>
            </button>
                </li>

                <li className="rounded-t-lg  px-2 py-2">
                 <button className="inline-block  lg:px-105 p-4 rounded-t-lg border text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
                <img
              alt="..."
              className=" font-extrabold w-78  p-2 "
              src={require("assets/img/servicebreak.png").default}/>
              <h6 className="text-sm font-bold">Road Tax<br></br>Services</h6>
            </button>
                </li>

                <li className="rounded-t-lg  px-2 py-2">
                <button className="inline-block  lg:px-105 p-4 rounded-t-lg border text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
                <img
              alt="..."
              className=" font-extrabold w-78  p-2 "
              src={require("assets/img/servicebreak.png").default}/>
              <h6 className="text-sm font-bold">Puspakom<br></br>Services</h6>
            </button>
                </li>

                <li className="rounded-t-lg  px-2 py-2">
                <button className="inline-block  lg:px-105 p-4 rounded-t-lg border text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
                <img
              alt="..."
              className=" font-extrabold w-78 lg:ml-1 p-2 "
              src={require("assets/img/servicebreak.png").default}/>
              <h6 className="text-sm font-bold">APAD Permit<br></br>Services</h6>
            </button>
                </li>

                <li className="rounded-t-lg  px-2 py-2">
                <button className="inline-block  lg:px-105 p-4 rounded-t-lg border text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"  onClick={() => {setDash(false); setContact(false); setProfile(true);setCreate(false);  setSetting(false); setFirst(true);}}>
                <img
              alt="..."
              className=" font-extrabold w-78 lg:ml-1 p-2 "
              src={require("assets/img/servicebreak.png").default}/>
              <h6 className="text-sm font-bold">Singapore VEP<br></br>Services</h6>
            </button>
                </li>
              </ul>
            </div>
        </div>

      </section>
              
           </div>
          </div>
        
       </section> 

     
     
      <Footer />
    </>
  );
}