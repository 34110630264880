import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";




export default function Vendor() {
  return (
    <>
      <IndexNavbar fixed />
      <section className=" w-full md:pb-32 ">
      <img
              alt="..."
              className=" font-extrabold  "
              src={require("assets/img/TRANSPORT.jpg").default}/>

              <div className="md:px-40 pt-8 text-center py-2 md:pt-24">
                <h6 className="md:text-4xl text-2xl ttt  text-blueMagenta font-bold">
                Do You Have A Workshop
                </h6>
                <p className="text-base abc pt-2 text-darkSlate ">
                If the answer is <b>YES</b>, we want you! Increase your sales margin with <b>24/7 Rescue Link™ </b>
                App where new customers can acknowledge your workshop location and make a request for 
                booking or you go to their vehicle location to fix their problem. Either way, we want to 
                bring more customer to you. Register your workshop now as our vendor. Don't wait until the quota runs out!
                </p>
                </div>
             <div className="md:py-2 pt-8 text-center py-4 ">
             <h6 className="md:text-4xl text-2xl ttt text-blueMagenta font-bold">
               We Need Vendors as follows
                </h6>
                </div>

                
                <div className="container mx-auto justify-center  mt-2  flex  flex-row lg:flex-row flex-col ">
                <div class="hover:-mt-4 flex break-words place transition ease-out duration-150" style={{padding: "1%"}}>
            <div className="w-36 p-4">
              <img
                alt="..."
                className=" w-20 mx-auto"
                src={require("assets/img/vmechanic.png").default} />
                <h6 className="text-lg py-2 text-blueMagenta font-bold ttt text-center">Mechanic</h6>
            </div>
         </div>


         <div class="hover:-mt-4  flex break-words place transition ease-out duration-150" style={{padding: "1%"}}>
            <div className="w-36 p-4">
              <img
                alt="..."
                className="w-20 mx-auto "
                src={require("assets/img/cartow.png").default} />
                <h6 className="text-lg py-2 text-blueMagenta font-bold ttt text-center">Towing</h6>
            </div>
         </div>
          
         <div class="hover:-mt-4 flex break-words place transition ease-out duration-150" style={{padding: "1%"}}>
            <div className="w-36 p-4">
              <img
                alt="..."
                className="w-20 mx-auto"
                src={require("assets/img/vtyre.png").default} />
                <h6 className="text-lg py-2 text-blueMagenta font-bold ttt text-center">Tyre</h6>
            </div>
         </div>
          
          
         <div class="hover:-mt-4 flex break-words place transition ease-out duration-150" style={{padding: "1%"}}>
            <div className="w-36 p-4">
              <img
                alt="..."
                className=" w-20 mx-auto"
                src={require("assets/img/wire.png").default} />
                <h6 className="text-lg py-2 text-blueMagenta font-bold ttt text-center">Wiring and Battery</h6>
            </div>
         </div>
          </div>
  
                            {/* Second Row */}
                            <div className="container justify-center mx-auto   mt-2  flex  lg:flex-row flex-col">
                <div class="hover:-mt-4 flex break-words place transition ease-out duration-150" style={{padding: "1%"}}>
            <div className="w-36 p-2">
              <img
                alt="..."
                className="w-20 mx-auto"
                src={require("assets/img/welding2.png").default} />
                <h6 className="text-lg py-2 text-blueMagenta font-bold ttt text-center">Welding</h6>
            </div>
         </div>


         <div class="hover:-mt-4  flex break-words place transition ease-out duration-150" style={{padding: "1%"}}>
            <div className="w-36 p-2">
              <img
                alt="..."
                className=" w-20 mx-auto"
                src={require("assets/img/spare2.png").default} />
                <h6 className="text-lg py-2 text-blueMagenta font-bold ttt text-center">Spare Parts</h6>
            </div>
         </div>
          
         <div class="hover:-mt-4 flex break-words place  transition ease-out duration-150" style={{padding: "1%"}}>
            <div className="w-36 p-2">
              <img
                alt="..."
                className="  w-20 mx-auto"
                src={require("assets/img/car-painting.png").default} />
                <h6 className="text-lg py-2 text-blueMagenta font-bold ttt text-center">Denting and Painting</h6>
            </div>
         </div>
          
          
         <div class="hover:-mt-4  flex break-words place transition ease-out duration-150" style={{padding: "1%"}}>
            <div className="w-36 p-2">
              <img
                alt="..."
                className=" w-20 mx-auto"
                src={require("assets/img/aircond3.png").default} />
                <h6 className="text-lg py-2 text-blueMagenta font-bold ttt text-center">Aircond and Accessories</h6>
            </div>
         </div>
          </div>

                                             {/* Third Row*/}

              <div className="container mx-auto  mt-2 justify-center flex  lg:flex-row flex-col">
                <div class="hover:-mt-4 flex break-words place transition ease-out duration-150" style={{padding: "1%"}}>
             <div className="w-36 p-2">
              <img
                alt="..."
                className=" w-20 mx-auto"
                src={require("assets/img/tun.png").default} />
                <h6 className="text-lg py-2 text-blueMagenta font-bold ttt text-center">Vehicle Tunning</h6>
            </div>
         </div>


         <div class="hover:-mt-4 flex break-words  place transition ease-out duration-150" style={{padding: "1%"}}>
            <div className="w-36 p-2">
              <img
                alt="..."
                className=" w-20 mx-auto"
                src={require("assets/img/unlock2.png").default} />
                <h6 className="text-lg py-2 text-blueMagenta font-bold ttt text-center">Unlocking and <br></br>Keyservice</h6>
            </div>
         </div>
          
         <div class="hover:-mt-4  flex break-words place transition ease-out duration-150" style={{padding: "1%"}}>
            <div className="w-36 p-2">
              <img
                alt="..."
                className=" w-20 mx-auto"
                src={require("assets/img/carwash2.png").default} />
                <h6 className="text-lg py-2 text-blueMagenta font-bold ttt text-center">Washing</h6>
            </div>
         </div>

         <div class="hover:-mt-4  flex break-words place transition ease-out duration-150" style={{padding: "1%"}}>
             <div className="w-36 p-2">
              
                
            </div>
         </div>
          </div> 
      </section>
      <Footer/>
      </>
  );
}