import React, { useState } from "react";
import emailjs from "emailjs-com";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { comment } from "postcss";



export default function Contact() {

  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [message, setMessage] = useState('')
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [emailregexError, setEmailRegexError] = useState(false)
  const [telError, setTelError] = useState(false)
  const [telregexError, setTelRegexError] = useState(false)
  const [messageError, setMessageError] = useState(false)



 const handleSubmit = (e) => {
    e.preventDefault();

    if (firstName.length === 0 || email.length == 0 || (tel.length === 0) || message.length === 0) {
      setNameError(true);
      setEmailError(true);
      setTelError(true);
      setMessageError(true);
      alert("Please fill all fields")
    }


    else if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))){
      setEmailRegexError(true);
    }

    else if (!(/^[6-9]\d{9}$/.test(tel))) {
      setTelRegexError(true);
    }

    else {
      console.log("First Name: ", firstName);
      console.log("Email: ", email);
      console.log("Tel: ", tel);
      console.log("Message: ", message);
    

    emailjs.sendForm('service_c7b4xbs', 'template_9rvioxl', e.target, 'gsQIuUBYnYvmGgwSO')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset()

    alert("Form Submitted Successfully")
    }

    
    
    
  }

  return (
    <>
      <IndexNavbar fixed />

      <section className=" container mx-auto  md:pl-60 md:py-24 md:pt-12 pt-8 bg-no-repeat justify-center" >

        <div className="justify-center flex flex-wrap flex-wrap-reverse px-2">
          <div className="w-full md:w-12/12 lg:w-12/12 ">
            <div className="flex md:pl-10  md:flex-wrap flex-wrap-reverse justify-center">

              {/* E-commerce*/}
              <div className="w-full bg-White border md:pl-10 rounded shadow-lg pl-10 md:pt-20 md:w-4/12 lg:w-4/12  ">


                <h6 className="md:text-2xl text-xl pt-6 ttt font-extrabold pb-2 text-left">
                  Address
                </h6>
                
                <p className="text-base text-black font-normal text-left">
                  <b>24/7 RESCUE LINK</b>,<br></br>
                  Suite A-3-9, 8 Avenue<br></br>  Business Centre Jalan 8/1,
                  46050 <br></br>Petaling Jaya, Selangor Darul Ehsan, Malaysia.
                </p>
                <h6 className="md:text-2xl text-xl ttt font-extrabold py-2 text-left">
                  Email
                </h6>
                <p className="text-base text-black font-normal text-left">
                  enquiry@24by7rescuelink.com

                </p>
                <h6 className="md:text-2xl text-xl ttt font-extrabold py-3 text-left">
                  Customer careline
                </h6>
                <p className="text-base text-black font-normal text-left">
                  <b>Malaysia   : </b>+6012-2529202<br></br>
                  <b>Singapore  : </b>+65-93516105
                </p>
                <h6 className="md:text-2xl text-xl ttt font-extrabold py-3 text-left">
                  Contact Us
                </h6>
                <p className="text-base text-black pb-4 font-normal text-left">
                  <b>Tel  : </b>03-79627056<br></br>
                  <b>Fax  :</b> 03-79627001
                </p>
              </div>


              <div className="w-10/12 md:w-6/12 sm:pt-28 lg:w-6/12 lg:pl-22   sm:pb-98" >
                <div className="rounded-t mb-0  px-6">
                  <div className="text-center sm:pt-28 mt-12 mb-3">
                    <h2 className=" font-bold ttt text-center text-xl text-blueWhale">

                      Build Your Career with 24/7
                      <h2 className="text-2xl font-extrabold pt-2">RESCUE LINK</h2>
                    </h2>
                  </div>

                </div>

                <div className="flex-auto px-2 lg:px-10 py-10 mt-4 pt-0">

                  <p className="font-bold text-green-600 text-center"></p>
                  <p className="font-bold text-red-500 text-center"></p>


                  <form onSubmit={handleSubmit}>
                    <div className="relative w-full py-2 ">

                      <input type="text"
                        className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Name" name="name" onChange={e => setFirstName(e.target.value)}
                      />
                      {nameError && firstName.length === 0 ?
                        <label className="error">Enter Your Name</label> : ""}
                    </div>

                    <div className="relative w-full py-2">
                      <input type="text"
                        className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email" name="email" onChange={e =>setEmail(e.target.value)+  setEmailRegexError(e.target.value)} 
                      />
                      {emailError && email.length === 0 ? 
                        <label className="error">Enter Your Email</label> : ""}
                        {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)))?
                        <label className="error">Please Enter Valid Email</label> : ""}
                        


                      <div className="relative w-full py-2">
                        <input
                          className="border mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Contact Number" name="tel"  onChange={e =>setTel(e.target.value)+ setTelRegexError(e.target.value)}
                        />
                        {telError && tel.length === 0 ?
                          <label className="error">Enter Your Contact Number</label> : ""}
                        {telregexError && (!(/^[6-9]\d{9}$/.test(tel))) ?
                        <label className="error">Please Enter Valid Number</label> : ""}

                          
                      </div>

                      <div className="relative w-full ">
                        <textarea
                          className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Message" name="message" onChange={e => setMessage(e.target.value)}
                        />
                        {messageError && message.length === 0 ?
                          <label className="error">Please Enter Your Message</label> : ""}
                      </div>
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>

              </div>

              {/* <div className="w-full  lg:w-8/12 sm:pt-52 lg:pt-24  px-4 sm:py-84">
                <div className="w-full  lg:w-12/12 sm:pt-52  lg:pt-24  px-4 ">
                  <img
          className="h-350-px"
          src={require("assets/img/bnr1.png").default}
          alt="..."
        /></div>
                  <section className=" lg:pt-12 ">

                    <div className="container mx-auto">
                      
                        <div className="w-full lg:w-12/12 ">
                          <div className="flex flex-wrap ">
                          <div class="mb-6" style={{padding: "1%"}}>
  <div className="w-80 border bg-white p-8 hover:bg-black-200  hover:shadow-lg">
    <h2 className="text-xl ttt text-blueGray-500 hover:text-white font-extrabold">
     Address
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-normal">24/7 RESCUE LINK<br></br>
Suite A-3-9, 8 Avenue Business Centre Jalan 8/1,
46050 Petaling Jaya, Selangor Darul Ehsan, Malaysia.
</p>
    
  </div>
</div>

<div class=" mb-6" style={{padding: "1%"}}>
  <div className="w-full lg-12/12">
  <div className="w-full lg-6/12">
  <div className="w-80 border bg-white p-8 hover:bg-black-200 hover:shadow-lg">
    <h2 className="text-xl ttt text-blueGray-500  font-extrabold">
      Email
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-normal">
      <ul>
      <li>enquiry@24by7rescuelink.com</li>
      <li>sales@24by7rescuelink.com</li>
      <li>insurance@24by7rescuelink.com</li>
<li>admin@24by7rescuelink.com</li>
<li>financedept@24by7rescuelink.com</li>
</ul>
    </p>
    </div>
  </div>
  <div className="w-full  lg:w-8/12">
    Haoi
  </div>
  </div>
</div>       
                          </div>
                        </div>
                      </div>
     
                  </section>

                
  </div>*/}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}