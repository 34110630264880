import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";



export default function Career() {
  return (
    <>
      <IndexNavbar fixed />

      <section className="  ">
      <img
              alt="..."
              className=" font-extrabold  "
              src={require("assets/img/TRANSPORT 3.jpg").default}/></section>
              <section className=" w-full">

              <div className="w-full md:py-24 py-6  md:px-32">
               
                      <h6 className="md:text-4xl text-2xl text-center font-bold abc text-blueMagenta">
                Build Your Carrer with 24/7 RESCUE LINK
                </h6>
                <p className="md:text-xl text-base pt-3 px-4 text-darkSlate text-center text-left ">
                We are looking for ambitious, innovative-minded talent to join our team.
                </p>

                <p className="md:text-xl text-base text-darkSlate text-center ">
                We review application on a rolling basis. Email your cover Letter and CV to 
                </p>

                <p className="text-2xl pt-3 text-blueMagenta text-center  font-extrabold ">
                hr@24by7rescuelink.com
                </p>
                      
                   
              </div>
              </section>
    
      <Footer/>
      </>
  );
}