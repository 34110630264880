import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";




export default function Investment() {
  return (
    <>
      <IndexNavbar fixed />
      <section className="  w-full ">
      <img
              alt="..."
              className=" font-extrabold "
              src={require("assets/img/TRANSPORT 2.jpg").default}/></section>

          <section className="px-4 w-full md:pb-32 ">
              <div className="md:px-40 pt-6">
                <h6 className="md:text-4xl text-2xl ttt  text-blueMagenta font-bold">
                Investors
                </h6>
                <p className="text-base abc text-darkSlate ">
                We are on a mission to change the travel challanges! To accomplish this audacious vision, <br></br>
                we are open to investments with great people, who can travel with us in long term.
                </p>
                </div>
             <div className="md:py-2 md:px-40 py-6">
                <h6 className="md:text-2xl xl abc text-blueMagenta font-bold">
                24/7 Rescue Link™ is a low risk and high return investments.
                </h6>

                <p className="text-base abc text-darkSlate pt-2 md:pr-52 pr-6">
                We don't take money from individuals. We will make investments only from seasoned entrepreneurs and 
                professional Angel Investors who have been through an entrepreneurial journey. This journey is very 
                tough and it will have a lot of ups & downs which will not be able to be handled by people who have 
                never gone through this before. Investing money in companies is a huge risk.So,we don’t want our Angel 
                Investor get into risk. The company is fully responsible for loss and we are looking for debt-funding 
                which low risk for our investor. Even though we have a clear plan and a great execution team, many factors matter. 
                It's a 3 Year plan minimum and a long-term plan. The intention is to build a great company that will touch
                 people's lives in a positive way and money will be a byproduct. We are aiming for a huge impact and returns as well.
                </p>
              </div>
            <div className="md:py-2 py-2 pb-6 md:px-40">
              <button className=" bg-blueMagenta  border-2  md:px-13 px-4 py-2 md:py-2">
                  <h6 className="text-xl text-white font-normal ">
                    <a href="/contact">
                    JOIN THE MISSION
                    </a>
                  </h6>
                </button>
            </div>

            <div className="md:py-2 py-2 pb-6 md:px-40">
            <h6 className="text-xl text-blueMagenta">
                  <b>Email Us : </b>  invest@24by7rescuelink.com
              </h6>
            </div>
            
      </section>
         

         
      
      <Footer/>
      </>
  );
}