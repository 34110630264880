import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/style.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";
import About from "components/About.js";
import Contact from "components/Contact.js";
import Service from "components/Service.js";
import Career from "components/Career.js";
import Investment from "components/Investment.js";
import Vendor from "components/Vendor.js";
import Total from "components/Total.js";
import Indo from "components/Indo.js"



ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} />
      {/* add routes without layouts */}
      <Route path="/landing" exact component={Landing} />
      <Route path="/profile" exact component={Profile} />
      <Route path="/" exact component={Index} />
      <Route path="/about" exact component={About}/>
      <Route path="/contact" exact component={Contact}/>
      <Route path="/service" exact component={Service}/>
      <Route path="/career" exact component={Career}/>
      <Route path="/investment" exact component={Investment}/>
      <Route path="/vendor" exact component={Vendor}/>
      <Route path="/total" exact component={Total}/>
      <Route path="/indo" exact component={Indo}/>
      
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
