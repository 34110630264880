import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";



export default function Service() {
  return (
    <>
      <IndexNavbar fixed />

      <section className=" text-center pt-12 bg-neutral-100 ">

<h2 className="font-extrabold ttt text-4xl text-blueWhale pt-20  pb-2">

  Our Services

</h2>

<h2 className="font-normal text-base text-black pb-2 pt-4">
Choose and book a car service experience and get upto <br></br>50% discount with the
goals or streamline operations in a particular area of the business, such as sales,<br></br> IT, finance, marketing, 
supply chain management, HR, operations, engineering, and security.
</h2>
</section>

{/* First Col */}


<div class="sm:px-12 px-20 py-10 flex flex-col md:flex-row md:flex-wrap justify-center bg-neutral-100">

<div class="hover:-mt-4 mb-6 flex break-words  transition ease-out duration-150" style={{
  padding: "1%"
}}>
  <div className="w-80  bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
    <img
      alt="..."
      className=" text-align:left  w-17 ml-auto mr-6 p-2 "
      src={require("assets/img/puncture.png").default}
    />
    <h2 className="text-lg ttt text-blueWhale  font-bold">
      Puncture Repair Assistance
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-semibold">Strategic planning is a must for every business, process
    </p>
    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      <a
        href={'/Strategic'}>
        <i class="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </button>
  </div>
</div>

<div class="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
  padding: "1%"
}}>
  <div className="w-80 bg-white p-8  hover:bg-lightBlue-200 hover:shadow-lg">
    <img
      alt="..."
      className="max-w-full w-17 ml-auto mr-6 p-2 "
      src={require("assets/img/flat.png").default}
    /> 
    <h2 className="text-lg text-blueWhale font-bold ttt">
     Flat Tyre Assistance
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-semibold">International expansion strategies are formal,
    </p>
    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      <a
        href={'/Inter'}>
       <i class="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </button>
  </div>
</div>

<div class="hover:-mt-4 mb-6 flex break-words  transition ease-out duration-150" style={{
  padding: "1%"
}}>
  <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
    <img
      alt="..."
      className="max-w-full w-17 ml-auto mr-6 p-2 "
      src={require("assets/img/cartow.png").default}
    />
    <h2 className="text-lg text-blueWhale  font-bold ttt">
     Car Towing Assistance
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-semibold">The three major roles in human resources</p>
    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2  py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      <a
        href={'/Hrmanage'}>
        <i class="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </button>
  </div>
</div>

<div class="hover:-mt-4 mb-6 flex break-words  transition ease-out duration-150" style={{
  padding: "1%"
}}>
  <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg ">
    <img
      alt="..."
      className=" max-w-full w-17 ml-auto mr-6 p-2 "
      src={require("assets/img/cartow.png").default}
    />
    <h2 className="text-lg text-blueWhale font-bold ttt">
      Bike Towing Assistance
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-semibold">Technology selection is a major criteria decision</p>
    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      <a
        href={'/Techno'}>
        <i class="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </button>
  </div>
</div>

{/* second Col*/}
<div class="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
  padding: "1%"
}}>
  <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
    <img
      alt="..."
      className="max-w-full w-17 ml-auto mr-6 p-2 "
      src={require("assets/img/battery.png").default}
    />
    <h2 className="text-lg text-blueWhale  font-bold ttt">
      Battery Jumpstart
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-semibold">Marketing is the best way to reach the new era through </p>
    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      <a
        href={'/Market'}>
       <i class="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </button>
  </div>
</div>

<div class="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
  padding: "1%"
}}>
  <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
    <img
      alt="..."
      className="max-w-full w-17 ml-auto mr-6 p-2 "
      src={require("assets/img/fuel.png").default}
    />
    <h2 className="text-lg text-blueWhale font-bold ttt">
     Emergency Fuel Delivery
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-semibold">In a business context, operational efficiency is a</p>
    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      <a
        href={'/Operation'}>
       <i class="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </button>
  </div>
</div>

<div class="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
  padding: "1%"
}}>
  <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
    <img
      alt="..."
      className="max-w-full w-17 ml-auto mr-6 p-2"
      src={require("assets/img/mechanical.png").default}
    />
    <h2 className="text-lg text-blueWhale font-bold ttt">
      Mechanical Repairs
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-semibold">ISO 9001 certification helps organizations to develop</p>
    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      <a
        href={'/Certificate'}>
        <i class="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </button>
  </div>
</div>

<div class="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
  padding: "1%"
}}>
  <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
    <img
      alt="..."
      className="max-w-full w-17 ml-auto mr-6 p-2"
      src={require("assets/img/cable.png").default}
    />
    <h2 className="text-lg text-blueWhale font-bold ttt">
    Cable Replacement
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-semibold">A trademark is a type of intellectual property</p>
    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      <a
        href={'/Trademark'}>
        <i class="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </button>
  </div>
</div>

{/* Third Col */}

<div class="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
  padding: "1%"
}}>
  <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
    <img
      alt="..."
      className="max-w-full w-17 ml-auto mr-6 p-2"
      src={require("assets/img/cartow.png").default}
    />
    <h2 className="text-lg text-blueWhale font-extrabold ttt">
      Vehicle Starting Trouble
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-semibold">Financial management may be defined as the area</p>
    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      <a
        href={'/Finance'}>
      <i class="fa fa-angle-right " aria-hidden="true"></i>
     </a>
    </button>
   
  </div>
</div>

<div class="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
  padding: "1%"
}}>
  <div className="w-80  bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
    <img
      alt="..."
      className="max-w-full w-17 ml-auto mr-6 p-2"
      src={require("assets/img/fuse.png").default}
    />
    <h2 className="text-lg text-blueWhale font-bold ttt">
     Electrical & Fuse Repairs
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-semibold">Electronic commerce is the buying and selling </p>
    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      <a
        href={'/Commerce'}>
       <i class="fa fa-angle-right" aria-hidden="true"></i>
      </a>
      </button>
    
  </div>
</div>

<div class="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
  padding: "1%"
}}>
  <div className="w-80  bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
    <img
      alt="..."
      className="max-w-full w-17 ml-auto mr-6 p-2"
      src={require("assets/img/key.png").default}
    />
    <h2 className="text-lg text-blueWhale font-bold ttt">
      Key Recovery Services
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-semibold">Electronic commerce is the buying and selling </p>
    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      <a
        href={'/Commerce'}>
       <i class="fa fa-angle-right" aria-hidden="true"></i>
      </a>
      </button>
    
  </div>
</div>


<div class="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
  padding: "1%"
}}>
  <div className="w-80  bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
    <img
      alt="..."
      className="max-w-full w-17 ml-auto mr-6 p-2"
      src={require("assets/img/Chain.png").default}
    />
    <h2 className="text-lg text-blueWhale font-bold ttt">
      Chain Related Repairs
    </h2>
    <p className="mt-2 text-blueGray-500 text-base font-semibold">Electronic commerce is the buying and selling </p>
    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      <a
        href={'/Commerce'}>
       <i class="fa fa-angle-right" aria-hidden="true"></i>
      </a>
      </button>
    
  </div>
</div>
</div>


      <Footer/>
      </>
  );
}