import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-gray-50 pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >

        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl text-blueMagenta font-extrabold">24/7 Rescue Link</h4>
              <h5 className="text-lg mt-0 mb-2 font-bold text-black">
                “Internet + Rescue Assistance”
              </h5>
              <div className="mt-6 lg:mb-0 mb-6">
                <button
                  className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <a href="https://twitter.com/24by7rescuelink?t=JfS_rpOuZ8REK-pgP9zH7Q&s=09">
                    <i className="fab fa-twitter"></i>
                  </a>
                </button>
                <button
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                ><a href="https://www.facebook.com/24by7rescuelink/">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                </button>
                <button
                  className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <a href="https://www.linkedin.com/company/24-7-rescue-link">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </button>
                <button
                  className="bg-white text-red-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <a href="https://instagram.com/24by7rescuelink?igshid=YmMyMTA2M2Y=">
                    <i className="fab fa-instagram"></i>
                  </a>
                </button>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-left text-blueMagenta text-xl font-bold mb-2">
                    Useful Links
                  </span>
                  <ul className="list-unstyled">

                    <li>
                      <a
                        className="text-darkSlate hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href="/index"
                      >
                        Home
                      </a>
                    </li>

                    <li>
                      <a
                        className="text-darkSlate hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href="/about"
                      >
                        About Us
                      </a>
                    </li>
                    {/*<li>
                      <a
                        className="text-darkSlate hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href=""
                      >
                       Our Services
                      </a>
                    </li>*/}

                    <li>
                      <a
                        className="text-darkSlate hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href="/career"
                      >
                        Career
                      </a>
                    </li>


                    <li>
                      <a
                        className="text-darkSlate hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href="/investment"
                      >
                        Investors
                      </a>
                    </li>


                    <li>
                      <a
                        className="text-darkSlate hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href="/vendor"
                      >
                        Vendor
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-darkSlate hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href="/contact"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full  md:w-6/12 px-4">
                  <span className="block uppercase text-blueMagenta text-xl font-bold mb-2">
                    Address
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-darkSlate  font-normal block  text-base"
                        
                      >
                        <b>24/7 RESCUE LINK</b>,<br></br>
                        Suite A-3-9, 8 Avenue Business Centre Jalan
                        8/1,<br></br>
                        46050 Petaling Jaya,
                        Selangor<br></br> Darul Ehsan, Malaysia.

                      </a>
                    </li>
                    <li>
                      <a
                        className="text-darkSlate font-normal block  text-base"

                      >
                        <i className="text-darkSlate fas fa-envelope-open text-lg leading-lg " /> : enquiry@24by7rescuelink.com
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-darkSlate  font-normal block pb-2 text-base"

                      >
                        <i className="text-darkSlate fas fa-phone-volume text-lg leading-lg " /> : 03-79627056
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white  font-normal block pb-2 text-sm"
                        href="https://creative-tim.com/contact-us?ref=nr-footer"
                      >

                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className=" border-yellow-700" />
          <div className="flex flex-wrap items -center md:justify-between justify-center">
            <div className="w-full md:w-4/12  mx-auto text-center">
              <div className="text-sm text-blueMagenta pt-2 pb-2 font-normal">
                Copyright © {new Date().getFullYear()}- <a href="http://24by7rescuelink.com/"><b>24/7 Rescue Link</b></a>{" "}
                <a
                  href=""
                  className="text-blueMagenta hover:text-blueGray-800"
                >
                  Privacy & Policy
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
