import React, { useState } from "react";
import emailjs from "emailjs-com";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { comment } from "postcss";



export default function Contact() {

  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [message, setMessage] = useState('')
  const [fullError, setFullError] = useState(false)
  const [emailregexError, setEmailRegexError] = useState(false)
  const [telregexError, setTelRegexError] = useState(false)
  const [gender, setGender] = useState(false)
  const [myCar, setMyCar] = useState(false);

  const handleChange = (event) => {
    setMyCar(event.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (firstName.length === 0 || email.length === 0 || tel.length === 0 || message.length === 0 || gender.length === 0) {
      setFullError(true);
    }

    else if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
      setEmailRegexError(true);
    }

    else if (!(/^[6-9]\d{9}$/.test(tel))) {
      setTelRegexError(true);
    }

    else {
      console.log("First Name: ", firstName);
      console.log("Email: ", email);
      console.log("Tel: ", tel);
      console.log("Message: ", message);
      console.log("Gender: ", gender);
    

    emailjs.sendForm('service_re0yn5q', 'template_ime6wus', e.target, 'R6FqDMmbZS9TP-gvP')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    e.target.reset()
alert("Form submitted successfully")
  }
}

  return (
    <>
      <IndexNavbar fixed />

      <section className=" container mx-auto justify-center mt-12 " >


        <div className="rounded-t mb-0  px-6">
          <div className="text-center sm:pt-28  mb-3">

          </div>

        </div>

        <div className="flex-auto px-2 lg:px-10 py-10 mt-4 pt-0">

          <p className="font-bold text-green-600 text-center"></p>
          <p className="font-bold text-red-500 text-center"></p>


          <form onSubmit={handleSubmit}>
            <div className="relative w-1/2 py-2 ">

              <input type="text"
                className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Name" name="name" onChange={e => setFirstName(e.target.value)}
              />
              {fullError && firstName.length == 0 ?
                <label className="error">Enter Your Name</label> : ""}
            </div>

            <div className="relative w-1/2 py-2">
              <input type="text"
                className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)}
              />
              {fullError && email.length == 0 ?
                <label className="error">Enter Your Email</label> : ""}
              {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                <label className="error">Please Enter Valid Email</label> : ""}



              <div className="relative w-full py-2">
                <input
                  className="border mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Contact Number" name="tel" onChange={e => setTel(e.target.value) + setTelRegexError(e.target.value)}
                />
                {fullError && tel.length == 0 ?
                  <label className="error">Enter Your Contact Number</label> : ""}
                {telregexError && (!(/^[6-9]\d{9}$/.test(tel))) ?
                  <label className="error">Please Enter Valid Number</label> : ""}
              </div>

              <div className="relative w-full py-2">
                <div className="border mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                  <input type="radio" value="MALE" name="gender" />Male<br></br>
                  <input type="radio" value="FEMALE" name="gender" /> Female
                </div>
              </div>

              
              <div className="relative w-full py-2">
              <select className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" value={myCar} onChange={handleChange}>
                <option value="Ford">India</option>
                <option value="Volvo">USA</option>
                <option value="Fiat">China</option>
              </select>
              </div>


              <div className="relative w-full ">
                <textarea
                  className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Message" name="message" onChange={e => setMessage(e.target.value)}
                />
                {fullError && message.length === 0 ?
                  <label className="error">Please Enter Your Message</label> : ""}
              </div>
            </div>

            <div className="text-left mt-6">
              <button
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase  py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-1/2 ease-linear transition-all duration-150"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
}