/*eslint-disable*/
import React, { useState } from "react";
import Tabs, { TabPane } from 'rc-tabs';
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import bnr11 from "assets/img/bnr11.jpg";
import logosvg from "assets/img/logosvg.svg";
import sky3 from "assets/img/sky3.jpg";
import road1 from "assets/img/road1.jpeg"


export default function Index() {

  const [first, setFirst] = useState(true);
  const [dash, setDash] = useState(false);
  const [profile, setProfile] = useState(false);
  const [setting, setSetting] = useState(false);
  const [contact, setContact] = useState(false);
  const [create, setCreate] = useState(false);
  const [light, setLight] = useState(false);
  const [heavy, setHeavy] = useState(false);

  return (
    <>
      <IndexNavbar fixed />

      <section className="header items-center  ">

        {/*<img
          alt="..."
          className=" font-extrabold"
          src={require("assets/img/rescuegif2.gif").default} />*/}
        <section className=" w-full">
         
        <img className=""
          src={require("assets/img/rescueoriginalgif.gif").default}
          alt="...">
        </img>
        
    
        

      </section>
</section>

      {/*<div className=" sm:pt-0">
              <h2 className="font-bold text-5xl text-white">
                RESCUE LINK
              </h2>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                
                <a
                  href="https://tailwindcss.com/?ref=creativetim"
                  className="text-blueGray-600"
                  target="_blank"
                >
                  
                </a>
                
              </p>
              <div className="">
                <a
                  href="/about"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Get started
                </a>
                <a
                  href="/contact"
                  className="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Contact Us
                </a>
              </div>
            </div>*/}


      {/*<img
          className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
          src={require("assets/img/pattern_react.png").default}
          alt="..."
        />*/}


      {/*Tab Control*/}

      <section>

      </section>


      <section className="container mx-auto md:py-20 py-6  px-2 ">
        <h2 className="font-extrabold ttt  text-4xl md:pt-12 pt-16  text-blueMagenta pb-2 text-center  pb-2">
          Our Services
        </h2>

        <h2 className="font-normal text-center text-base text-darkSlate pb-2 pt-4">
          50% discount with the goals or streamline operations in a particular area of the business, such as sales
        </h2>
        <div className="container mx-auto  mb-4 border-gray-200 px-6   mt-4 ">
          <ul className="flex flex-wrap  text-sm font-medium text-center justify-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
            <li className="rounded  px-2 py-2" role="presentation ">
              <button className="inline-block md:p-2 p-2 bg-blueMagenta md:px-10 px-8 rounded text-white" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                <img
                  alt="..."
                  className=" font-extrabold md:w-20 w-16  p-2 "
                  src={require("assets/img/servicebreak2.png").default} />
                <h6 className="text-base font-bold">Breakdown<br></br>Services</h6>
              </button>
            </li>
            <li className="rounded  px-2 py-2" role="presentation">
              <button className="inline-block md:p-2 p-2 bg-blueMagenta md:px-10 px-6 rounded text-white" id="dashboard-tab" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false" onClick={() => { setDash(true); setContact(false); setProfile(false); setCreate(false); setSetting(false); setFirst(false); }}>
                <img
                  alt="..."
                  className=" font-extrabold md:w-20 w-16 p-2 "
                  src={require("assets/img/servicespare.png").default} />
                <h6 className="text-base font-bold">Maintanence<br></br>Services</h6>
              </button>
            </li>
            <li className="rounded  px-2 py-2" role="presentation">
              <button className="inline-block md:p-2 p-2 bg-blueMagenta md:px-10 px-8 rounded text-white" id="settings-tab" data-tabs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false" onClick={() => { setDash(false); setContact(false); setProfile(false); setCreate(false); setSetting(true); setFirst(false); }}>
                <img
                  alt="..."
                  className="font-extrabold md:w-20 w-16 p-2 "
                  src={require("assets/img/serviceinsurance.png").default} />
                <h6 className="text-base font-bold">Insurance<br></br>Services</h6>
              </button>
            </li>
            <li className="rounded  px-2 py-2" role="presentation">
              <button className="inline-block md:p-2 p-2 bg-blueMagenta md:px-4 px-2 rounded text-white" id="contacts-tab" data-tabs-target="#contacts" type="button" role="tab" aria-controls="contacts" aria-selected="false" onClick={() => { setDash(false); setContact(true); setProfile(false); setCreate(false); setSetting(false); setFirst(false); }}>
                <img
                  alt="..."
                  className="lg:ml-1 ml-7  font-extrabold md:w-20 w-16  p-2 "
                  src={require("assets/img/serviceparts.png").default} />
                <h6 className="text-base font-bold">Spare Parts and<br></br> Vehicle Trading</h6>
              </button>
            </li>

            <li className="rounded  px-2 py-2" role="presentation">
              <button className="inline-block md:p-2 p-2 bg-blueMagenta md:px-4 px-2 rounded text-white" id="create-tab" data-tabs-target="#create" type="button" role="tab" aria-controls="create" aria-selected="false" onClick={() => { setDash(false); setContact(false); setProfile(false); setSetting(false); setCreate(true); setFirst(false); }}>
                <img
                  alt="..."
                  className=" font-extrabold md:w-20 w-16 p-2 "
                  src={require("assets/img/servicedata.png").default} />
                <h6 className="text-base font-bold">Documents and<br></br> Data management</h6>
              </button>
            </li>
          </ul>
        </div>

        <div id="myTabContent">
          <div className=
            {
              "p-4  rounded-lg dark:bg-gray-800 " +
              (first ? null : "hidden ") +
              (profile ? null : "hidden")
            } id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <ul className="container mx-auto flex flex-wrap  md:pl-60 text-sm font-medium text-center justify-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
              <li className="rounded  px-2 py-2">
                <button className="inline-block md:p-2 p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16"
                    src={require("assets/img/wrench.png").default} />
                  <h6 className="text-sm font-bold">Mechanic<br></br>Services</h6>
                </button>
              </li>

              <li className="rounded-t-lg  px-2 py-2">
                <button className="inline-block md:p-2 p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 "
                    src={require("assets/img/towtruck.png").default} />
                  <h6 className="text-sm font-bold">Towing<br></br>Services</h6>
                </button>
              </li>

              <li className="rounded-t-lg  px-2 py-2">
                <button className="inline-block md:p-2 p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 "
                    src={require("assets/img/flat.png").default} />
                  <h6 className="text-sm font-bold">Tyre<br></br>Services</h6>
                </button>
              </li>

              <li className="rounded px-2 py-2">
                <button className="inline-block md:p-2 p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16"
                    src={require("assets/img/cable.png").default} />
                  <h6 className="text-sm font-bold">Wiring and<br></br>Battery</h6>
                </button>
              </li>

              <li className="rounded  px-2 py-2">
                <button className="inline-block md:p-2 p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold mx-auto w-16"
                    src={require("assets/img/keyservice1.png").default} />
                  <h6 className="text-sm font-bold">Unlock and<br></br> Key Services</h6>
                </button>
              </li>
            </ul>
          </div>

          {/*Second Tab */}

          <div className=
            {
              "p-4  rounded-lg dark:bg-gray-800 " +
              (dash ? null : "hidden")
            } id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
            <ul className="container mx-auto flex flex-wrap justify-center  text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
              <li className="rounded  px-2 py-2">
                <button className="inline-block p-2 border-2 md:px-10 px-6 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 "
                    src={require("assets/img/Painting.png").default} />
                  <h6 className="text-sm font-bold">Denting and<br></br>Painting</h6>
                </button>
              </li>

              <li className="rounded  px-2 py-2">
                <button className="inline-block p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16"
                    src={require("assets/img/aircond2.png").default} />
                  <h6 className="text-sm font-bold">Aircond and<br></br>Accessories</h6>
                </button>
              </li>

              <li className="rounded px-2 py-2">
                <button className="inline-block p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/tunning.png").default} />
                  <h6 className="text-sm font-bold">Vehicle<br></br>Tunning</h6>
                </button>
              </li>

              <li className="rounded px-2 py-2">
                <button className="inline-block p-2 border-2 md:px-12 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/customized.png").default} />
                  <h6 className="text-sm font-bold">Customized<br></br>Services</h6>
                </button>
              </li>

              <li className="rounded px-2 py-2">
                <button className="inline-block p-2 border-2 md:px-12 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/fleet.png").default} />
                  <h6 className="text-sm font-bold">Fleet Lab<br></br>Test</h6>
                </button>
              </li>


              <li className="rounded  px-2 py-2">
                <button className="inline-block p-2 border-2 md:px-12 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/weldinggg.png").default} />
                  <h6 className="text-sm font-bold">Welding<br></br>Services</h6>
                </button>
              </li>
              <li className="rounded px-2 py-2">
                <button className="inline-block border-2 p-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/carwash.png").default} />
                  <h6 className="text-sm font-bold">Washing<br></br>Services</h6>
                </button>
              </li>
            </ul>
          </div>


          {/* third services */}
          <div className=
            {
              "p-4  rounded-lg dark:bg-gray-800 " +
              (setting ? null : "hidden")
            }
            id="settings" role="tabpanel" aria-labelledby="settings-tab">
            <ul className="container mx-auto flex flex-wrap justify-center  text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
              <li className="rounded px-2 py-2">
                <button className="inline-block p-2 border-2 md:px-10 px-6 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/claim.png").default} />
                  <h6 className="text-sm font-bold">Claim<br></br>Arrangement</h6>
                </button>
              </li>

              <li className="rounded px-2 py-2">
                <button className="inline-block p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/rescue.png").default} />
                  <h6 className="text-sm font-bold">Rescue<br></br>Management</h6>
                </button>

              </li>

              <li className="rounded  py-2">
                <button className="inline-block p-2 border-2 md:px-4 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/insurancebrokerage.png").default} />
                  <h6 className="text-sm font-bold">Insurance Brokerage <br></br>and Renewal</h6>
                </button>
              </li>

              <li className="rounded px-2 py-2">
                <button className="inline-block p-2 border-2 md:px-4 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/arrange.png").default} />
                  <h6 className="text-sm font-bold">Arrange Adjuster and<br></br> Panel Workshop</h6>
                </button>

              </li>
            </ul>
          </div>

          {/* Fourth Service*/}

          <div className=
            {
              "p-4  rounded-lg dark:bg-gray-800 " +
              (contact ? null : "hidden")
            } id="contacts" role="tabpanel" aria-labelledby="contacts-tab">
            <ul className="container mx-auto flex flex-wrap justify-center  text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
              <li className="rounded px-2 py-2">
                <button className="inline-block p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/warranty.png").default} />
                  <h6 className="text-sm font-bold">Warranty<br></br>Services</h6>
                </button>

              </li>

              <li className="rounded px-2 py-2">
                <button className="inline-block p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className="font-extrabold w-16 mx-auto"
                    src={require("assets/img/vehicletrading.png").default} />
                  <h6 className="text-sm font-bold">Vehicle<br></br>Trading</h6>
                </button>
              </li>

              <li className="rounded-t-lg  px-2 py-2">
                <button className="inline-block  p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className="font-extrabold w-16 mx-auto"
                    src={require("assets/img/delivered.png").default} />
                  <h6 className="text-sm font-bold">Delivery<br></br>Services</h6>
                </button>
              </li>

              <li className="rounded px-2 py-2">
                <button className="inline-block  p-2 border-2 md:px-6 px-4 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className="font-extrabold w-16 mx-auto"
                    src={require("assets/img/quality.png").default} />
                  <h6 className="text-sm font-bold">Quality and<br></br>Re-conditioned </h6>
                </button>

              </li>

              <li className="rounded  px-2 py-2">
                <button className="inline-block p-2 border-2 md:px-6 px-4 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className="font-extrabold w-16 mx-auto"
                    src={require("assets/img/purchasing.png").default} />
                  <h6 className="text-sm font-bold">Purchasing made<br></br>Easy</h6>
                </button>
              </li>
            </ul>
          </div>

          {/* Fifth Service*/}


          <div className=
            {
              "p-4  rounded-lg dark:bg-gray-800 " +
              (create ? null : "hidden")
            } id="create" role="tabpanel" aria-labelledby="create-tab">
            <ul className="container mx-auto flex flex-wrap justify-center text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
              <li className="rounded px-2 py-2">
                <button className="inline-block  p-2 border-2 md:px-10 px-6 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto "
                    src={require("assets/img/driverand.png").default} />
                  <h6 className="text-sm font-bold">Driverand<br></br>Fleet Health</h6>
                </button>
              </li>

              <li className="rounded px-2 py-2">
                <button className="inline-block md:p-2 p-2 border-2 md:px-10 px-6 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/vehicleand.png").default} />
                  <h6 className="text-sm font-bold">Vehicle service<br></br>fault reminder</h6>
                </button>
              </li>

              <li className="rounded px-2 py-2">
                <button className="inline-block md:p-2 p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className="font-extrabold w-16 mx-auto"
                    src={require("assets/img/roadtax.png").default} />
                  <h6 className="text-sm font-bold">Road Tax<br></br>Services</h6>
                </button>
              </li>

              <li className="rounded  px-2 py-2">
                <button className="inline-block md:p-2 p-2 border-2 md:px-10 px-8 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/puspakam.png").default} />
                  <h6 className="text-sm font-bold px-2 ">Puspakom<br></br>Services</h6>
                </button>
              </li>

              <li className="rounded px-2 py-2">
                <button className="inline-block md:p-2 p-2 border-2 md:px-10 px-6 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto "
                    src={require("assets/img/apad.png").default} />
                  <h6 className="text-sm font-bold">APAD Permit<br></br>Services</h6>
                </button>
              </li>
              <li className="rounded  px-2 py-2">
                <button className="inline-block md:p-2 p-2 border-2 md:px-10 px-6 rounded text-black" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(true); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/singapore.png").default} />
                  <h6 className="text-sm font-bold">Singapore VEP<br></br>Services</h6>
                </button>
              </li>


            </ul>
          </div>
        </div>
      </section>


      {/*End */}


      <section className="bg-blueMagenta md:py-24 py-10">
        <div className="container mx-auto ">
          <h6 className="text-center md:text-2xl text-xl text-white font-bold">
            When you need
          </h6>
          <h6 className="text-center pb-2 md:text-5xl text-2xl  text-white font-extrabold">
            OUR SERVICE
          </h6>

          <div className="mt-2  px-8 flex  md:flex-row flex-col justify-center">
            <div className="hover:-mt-4 rounded-lg flex break-words  transition ease-out duration-150" style={{
              padding: "1%"
            }}>
              <div className="bg-white border-2 rounded-lg shadow-xl ">
                <img
                  alt="..."
                  className="max-w-310-px p-2"
                  src={require("assets/img/serve1.png").default} />
                <h6 className="text-xl  py-2 px-6  text-black font-normal ttt text-center">During Breakdown</h6>
              </div>
            </div>


            <div className=" rounded-lg flex break-words  transition ease-out duration-150" style={{
              padding: "1%"
            }}>
              <div className="bg-white border-2 rounded-lg shadow-xl ">
                <img
                  alt="..."
                  className="max-w-310-px p-2"
                  src={require("assets/img/caraccident.png").default} />


                <h6 className="text-xl py-2 px-6  text-black font-normal ttt text-center">During Accident</h6>


              </div>
            </div>

            <div className=" rounded-lg flex break-words  transition ease-out duration-150" style={{
              padding: "1%"
            }}>
              <div className="bg-white border-2 rounded-lg shadow-xl ">
                <img
                  alt="..."
                  className="max-w-310-px p-2"
                  src={require("assets/img/serve3.png").default} />
                <h6 className="text-xl py-2 px-6 text-black font-normal ttt text-center">During Natural Disaster</h6>
              </div>
            </div>


            <div className=" rounded-lg flex break-words  transition ease-out duration-150" style={{
              padding: "1%"
            }}>
              <div className="bg-white border-2 rounded-lg shadow-xl ">
                <img
                  alt="..."
                  className="max-w-310-px p-2"
                  src={require("assets/img/serve4.png").default} />


                <h6 className="text-xl py-2 px-6 text-black font-normal ttt text-center">During Man Made Disaster</h6>


              </div>
            </div>
          </div>
        </div>
      </section>


      {/*Types Fleet */}


      <section className=" md:py-24 py-10" >
        <div className="container mx-auto">
          <h6 className="text-center md:pr-12 pb-2 px-4 md:text-4xl  text-2xl text-blueMagenta font-extrabold">
            Types of fleets we covered
          </h6>
          <div className="mb-4 border-gray-200 mt-4 ">
            <ul className="container mx-auto flex flex-wrap  px-4 text-sm font-medium text-center justify-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
              <li className="mr-2 " role="presentation ">
                <button className="inline-block  p-2 bg-blueMagenta md:px-10 px-6 rounded text-white" id="light-tab" data-tabs-target="#light" type="button" role="tab" aria-controls="light" aria-selected="true" onClick={() => { setDash(false); setHeavy(false); setContact(false); setProfile(false); setLight(false); setCreate(false); setSetting(false); setFirst(true); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/lightmotor.png").default} />
                  <h6 className="text-base font-bold ">Light/Private<br></br> Vehicle</h6>
                </button>
              </li>
              <li className="mr-2" role="presentation">
                <button className="inline-block  p-2 bg-blueMagenta md:px-10  rounded text-white" id="heavy-tab" data-tabs-target="#heavy" type="button" role="tab" aria-controls="heavy" aria-selected="true" onClick={() => { setDash(false); setHeavy(true); setContact(false); setProfile(false); setLight(false); setCreate(false); setSetting(false); setFirst(false); }}>
                  <img
                    alt="..."
                    className=" font-extrabold w-16 mx-auto"
                    src={require("assets/img/heavyvehicle.png").default} />
                  <h6 className="text-base font-bold ">Heavy/Commercial<br></br> Vehicle</h6>
                </button>
              </li>

            </ul>
          </div>

          <div id="myTabContent">
            <div className=
              {
                "p-4  rounded-lg dark:bg-gray-800 " +
                (first ? null : "hidden ") +
                (light ? null : "hidden")
              } id="light" role="tabpanel" aria-labelledby="light-tab">
              <ul className="container mx-auto flex flex-wrap justify-center px-4 text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                <li className="rounded  px-2 py-2">
                  <button className="inline-block  p-2 border-2 md:px-6 px-4 rounded text-bluMagenta" id="light-tab" data-tabs-target="#light" type="button" role="tab" aria-controls="light" aria-selected="true" onClick={() => { setDash(false); setHeavy(false); setLight(true); setContact(false); setProfile(false); setCreate(false); setSetting(false); setFirst(true); }}>
                    <img
                      alt="..."
                      className=" font-extrabold w-16 mx-auto"
                      src={require("assets/img/motorcycle.png").default} />
                    <h6 className="text-base font-bold">Motor Cycles</h6>
                  </button>
                </li>

                <li className="rounded  px-2 py-2">
                  <button className="inline-block p-2 border-2 md:px-10 px-6 rounded text-bluMagenta" id="light-tab" data-tabs-target="#light" type="button" role="tab" aria-controls="light" aria-selected="true" onClick={() => { setDash(false); setHeavy(false); setLight(true); setContact(false); setProfile(false); setCreate(false); setSetting(false); setFirst(true); }}>
                    <img
                      alt="..."
                      className=" font-extrabold w-16 mx-auto  "
                      src={require("assets/img/cars.png").default} />
                    <h6 className="text-base font-bold ">Cars/Vans</h6>
                  </button>
                </li></ul>
            </div>

            {/*Second Tab */}
            <div className=
              {
                "p-4 bg-gray-50 rounded-lg dark:bg-gray-800 " +
                (heavy ? null : "hidden")
              } id="heavy" role="tabpanel" aria-labelledby="heavy-tab">
              <ul className="container mx-auto flex flex-wrap justify-center px-4 text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                <li className="rounded px-2 py-2">
                  <button className="inline-block  p-2 border-2 justify-center md:px-10 px-6 rounded text-bluMagenta" id="heavy-tab" data-tabs-target="#heavy" type="button" role="tab" aria-controls="heavy" aria-selected="true" onClick={() => { setDash(false); setContact(false); setLight(false); setHeavy(true); setProfile(false); setCreate(false); setSetting(false); setFirst(true); }}>
                    <img
                      alt="..."
                      className=" font-extrabold w-16 mx-auto "
                      src={require("assets/img/van.png").default} />
                    <h6 className="text-base font-bold">Vans</h6>
                  </button>
                </li>

                <li className="rounded  px-2 py-2">
                  <button className="inline-block  p-2 border-2 md:px-10 px-6 rounded text-bluMagenta" id="heavy-tab" data-tabs-target="#heavy" type="button" role="tab" aria-controls="heavy" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(false); setLight(false); setHeavy(true); setCreate(false); setSetting(false); setFirst(true); }}>
                    <img
                      alt="..."
                      className=" font-extrabold w-16 mx-auto"
                      src={require("assets/img/truck.png").default} />
                    <h6 className="text-base font-bold">Trucks</h6>
                  </button>
                </li>

                <li className="rounded px-2 py-2">
                  <button className="inline-block  p-2 border-2 md:px-10 px-6 rounded text-bluMagenta" id="heavy-tab" data-tabs-target="#heavy" type="button" role="tab" aria-controls="heavy" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(false); setLight(false); setHeavy(true); setCreate(false); setSetting(false); setFirst(true); }}>
                    <img
                      alt="..."
                      className=" font-extrabold w-16 mx-auto"
                      src={require("assets/img/bus.png").default} />
                    <h6 className="text-base font-bold">Buses</h6>
                  </button>
                </li>

                <li className="rounded px-2 py-2">
                  <button className="inline-block md:p-2 p-2 border-2 md:px-10  rounded text-bluMagenta" id="heavy-tab" data-tabs-target="#heavy" type="button" role="tab" aria-controls="heavy" aria-selected="true" onClick={() => { setDash(false); setContact(false); setProfile(false); setLight(false); setHeavy(true); setCreate(false); setSetting(false); setFirst(true); }}>
                    <img
                      alt="..."
                      className=" font-extrabold w-16 mx-auto"
                      src={require("assets/img/tractor.png").default} />
                    <h6 className="text-base font-bold">Duty Cranes </h6>
                  </button>
                </li></ul>

            </div>
          </div>
        </div>
      </section>





      {/*End */}


      <div className="line-container1 bg-neutral-100 py-6 mx-auto md:pb-16 hidden md:block">
        <h6 className="text-center font-bold text-4xl pt-16  text-blueMagenta">
          OUR PROCESS
        </h6>
        <svg width="1300" height="623" viewBox="0 0 782 693" fill="none" xmlns="http://www.w3.org/2000/svg" className="-mt-6" >
          <g clip-path="url(#clip0_0_1)">
            <path d="M397 532C501.934 532 587 449.397 587 347.5C587 245.603 501.934 163 397 163C292.066 163 207 245.603 207 347.5C207 449.397 292.066 532 397 532Z" stroke="#454848" stroke-width="5"></path>
            <g font-size="30" font-family="sans-serif" fill="#3b1d7d" stroke="none" text-anchor="middle">

              <image href={require("assets/img/logo.png").default} height="250" width="250" x="290" y="200" dx="0" />
            </g>
          </g>

          {/* 1st services*/}

          <circle cx="690" cy="110" r="13" stroke="none" stroke-width="1" fill="#b1072c" />
          <text fill="#fff" font-size="15" font-family="Verdana" x="685" y="115">1</text>
          <text x="674" y="145" fill="#454848" font-weight="normal" font-size="17px">
            Potential vehicle owners register and subscribe
          </text>
          <text x="675" y="170" fill="#454848" font-weight="normal" font-size="17px">
            the service packages at platform and  24/7 Rescue
          </text>
          <text x="677" y="195" fill="#454848" font-weight="normal" font-size="17px">
            Link™ have been verified customer profile.</text>
          <image href={require("assets/img/blue1.png").default} height="100" width="100" x="570" y="120" dx="0" />

          {/* 2th service*/}

          <image href={require("assets/img/blue6.png").default} height="100" width="100" x="620" y="305" dx="0" />
          <circle cx="740" cy="290" r="13" stroke="none" stroke-width="1" fill="#b1072c" />
          <text fill="#ffffff" font-size="15" font-family="Verdana" x="735" y="296">2</text>
          <text x="730" y="330" fill="#454848" font-weight="normal" font-size="17px">
            Our golden customer will contact our
          </text>
          <text x="730" y="355" fill="#454848" font-weight="normal" font-size="17px">
            nearest rescue team via the platform
          </text>
          <text x="730" y="380" fill="#454848" font-weight="normal" font-size="17px">
            during breakdown.</text>



          {/* 3rd service*/}
          <image href={require("assets/img/blue3.png").default} height="100" width="100" x="570" y="480" dx="0" />
          <circle cx="680" cy="480" r="13" stroke="none" stroke-width="1" fill="#b1072c" />
          <text fill="#ffffff" font-size="15" font-family="Verdana" x="676" y="485">3</text>
          <text x="670" y="520" fill="#454848" font-weight="normal" font-size="17px">
            Our nearest rescue team will receive notification
          </text>
          <text x="670" y="543" fill="#454848" font-weight="normal" font-size="17px">
            with breakdown details and location.
          </text>
          <text x="670" y="565" fill="#454848" font-weight="normal" font-size="17px">
          </text>


          {/* 4th service*/}
          <image href={require("assets/img/blue2.png").default} height="100" width="100" x="330" y="580" dx="0" />
          <circle cx="445" cy="600" r="13" stroke="none" stroke-width="1" fill="#b1072c" />
          <text fill="#ffffff" font-size="15" font-family="Verdana" x="440" y="605">4</text>
          <text x="440" y="635" fill="#454848" font-weight="normal" font-size="17px">
            Our rescue team will analysis the damages and quote  the charges,
          </text>
          <text x="440" y="658" fill="#454848" font-weight="normal" font-size="17px">
            24/7 Rescue Link™ service charges will add automatically, according
          </text>
          <text x="440" y="680" fill="#454848" font-weight="normal" font-size="17px">
            to customer’s packages subscribtion in e-quatation form. </text>

          {/* 5th service*/}
          <image href={require("assets/img/blue4.png").default} height="100" width="100" x="130" y="480" dx="0" />
          <circle cx="-105" cy="480" r="13" stroke="none" stroke-width="1" fill="#b1072c" />
          <text fill="#ffffff" font-size="15" font-family="Verdana" x="-110" y="485">5</text>
          <text x="-145" y="515" fill="#454848" font-weight="normal" font-size="17px">
            Our rescue team start repair the
          </text>
          <text x="-145" y="538" fill="#454848" font-weight="normal" font-size="17px">
            damages once customer accept
          </text>
          <text x="-145" y="561" fill="#454848" font-weight="normal" font-size="17px">
            the e-quatation.
          </text>




          {/* 6th service*/}
          <image href={require("assets/img/blue5.png").default} height="100" width="100" x="85 " y="290" dx="0" />
          <circle cx="-160" cy="270" r="13" stroke="none" stroke-width="1" fill="#b1072c" />
          <text fill="#ffffff" font-size="15" font-family="Verdana" x="-166" y="275">6</text>
          <text x="-180" y="305" fill="#454848" font-weight="normal" font-size="17px">
            Customer will pay the charges by
          </text>
          <text x="-180" y="328" fill="#454848" font-weight="normal" font-size="17px">
            Digital transaction or Cash
          </text>
          <text x="-180" y="354" fill="#454848" font-weight="normal" font-size="17px">
            On Delivery (COD) according to </text>
          <text x="-180" y="378" fill="#454848" font-weight="normal" font-size="17px">
            credit term.</text>



          {/* 7th service*/}
          <image href={require("assets/img/blue7.png").default} height="100" width="100" x="130" y="115" dx="0" />
          <circle cx="-130" cy="120" r="13" stroke="none" stroke-width="1" fill="#b1072c" />
          <text fill="#ffffff" font-size="15" font-family="Verdana" x="-135" y="125">7</text>
          <text x="-150" y="160" fill="#454848" font-weight="normal" font-size="17px">
            All repairing data will stored at cloud
          </text>
          <text x="-150" y="185" fill="#454848" font-weight="normal" font-size="17px">
            for customer’s future reference.
          </text>


        </svg>
      </div>



      {/* our Process mbl view */}


      <section className="container mx-auto md:hidden py-10 bg-neutral-100">
        <h6 className="text-blueMagenta text-center font-bold text-4xl pb-8">
          OUR PROCESS
        </h6>
        <div className="w-full flex justify-center px-4 ">
          <div className="w-6/12">
            <img
              alt="..."
              src={require("assets/img/blue1.png").default} />
          </div>
          <div className="w-8/12 px-2 ">
            Potential Truck & bus operators register at
            platform and "Truck Rescue” have been
            verified customer profile.
          </div>
        </div>


        <div className="w-full flex justify-center px-2 py-4 ">
          <div className="w-36  ">
            <img
              alt="..."
              src={require("assets/img/blue6.png").default} />
          </div>
          <div className="w-8/12 px-4 ">
            Potential Customer will contact vendors via “Truck Resue” Platform for breakdown services.
          </div>
        </div>

        <div className="w-full flex justify-center px-2 py-2">
          <div className="w-24">
            <img
              alt="..."
              src={require("assets/img/blue3.png").default} />
          </div>
          <div className="w-12/12 px-4 py-2">
            Mr.Vendor will receive notification with
            breakdown details and location.
          </div>
        </div>

        <div className="w-full flex justify-center px-2 py-4">
          <div className="w-28">
            <img
              alt="..."
              src={require("assets/img/blue2.png").default} />
          </div>
          <div className="w-8/12 px-4 py-2 ">
            Mr. Vendor will quote the breakdown
            charges, “Truck Rescue” service
            charges.
          </div>
        </div>


        <div className="w-full flex justify-center px-2 py-4">
          <div className="w-28">
            <img
              alt="..."
              src={require("assets/img/blue4.png").default} />
          </div>
          <div className="w-8/12 px-4 py-2">
            Mr.Vendor will repair the breakdown
            vehicle once customer accept the
            e-quatation.
          </div>
        </div>

        <div className="w-full flex justify-center px-2  py-4">
          <div className="w-44">
            <img
              alt="..."
              src={require("assets/img/blue5.png").default} />
          </div>
          <div className="w-8/12 ">
            The Happy customer will pay the breakdown payment by automatic deduction via “Truck Rescue” platform with digital transaction.
          </div>
        </div>

        <div className="w-full flex justify-center px-2  py-4">
          <div className="w-48">
            <img
              alt="..."
              src={require("assets/img/blue7.png").default} />
          </div>
          <div className="w-8/12  px-2">
            “Truck Rescue” is fully responsible for
            payment and pay to vendor according
            to credits term with deduction of service
            charges of Truck Rescue Platform.
          </div>
        </div>

      </section>



      {/*<section className=" lg:py-24 sm:pt-52" >
        <div className="container ">
          <div className="justify-center flex flex-wrap">
            <div className="w-full lg:w-12/12 ">
            <h6 className="text-center text-4xl pb-4 lg:pl-119 text-blueMagenta font-extrabold">How Our Platforms Work</h6>
        <div className="flex lg:flex-wrap lg:pl-32 sm:flex-wrap-reverse">*/}

      {/* E-commerce*/}

      {/*<div className="w-full lg:w-8/12  sm:pt-52 lg:pl-110 px-4 sm:py-84">
                <div className="flex lg:flex-wrap py-4 sm:flex-wrap-reverse hover:shadow-lg1 shadow-lg rounded">
                <div className=" lg:w-12/12  " >
                        <img
                        alt="..."
                        className=" font-extrabold w-77 lg:ml-1 p-2 "
              src={require("assets/img/ser-1.png").default}/>  
                  </div>
                  <div className=" lg:w-8/12 pl-4 sm:pt-52 text-white  sm:py-84">
                 Potential vehicle owners register and subscribe the service packages 
                 at platform and 24/7 Rescue Link™ have been verified customer profile. 
                    </div>
                  </div>
                  <br></br>

                  <div className="flex lg:flex-wrap py-2  sm:flex-wrap-reverse hover:shadow-lg1 shadow-lg rounded">
                <div className=" lg:w-12/12  " >
                        <img
                        alt="..."
                        className=" font-extrabold w-77 lg:ml-1 p-2 "
              src={require("assets/img/ser-2.png").default}/>  
                  </div>
                  <div className=" lg:w-8/12 pl-4 sm:pt-52 text-white  sm:py-84">
                  Our golden customer will contact our nearest rescue team via the platform during breakdown.
                    </div>
                  </div>
                  <br></br>

                  <div className="flex lg:flex-wrap py-2 sm:flex-wrap-reverse hover:shadow-lg1 shadow-lg rounded">
                <div className=" lg:w-12/12 " >
                        <img
                        alt="..."
                        className=" font-extrabold w-77 lg:ml-1 p-2 "
              src={require("assets/img/ser-3.png").default}/>  
                  </div>
                  <div className=" lg:w-8/12 pl-4 sm:pt-52 text-white  sm:py-84">
                  Our nearest rescue team will receive notification with breakdown details and location.
                    </div>
                  </div>
                  <br></br>

                  <div className="flex lg:flex-wrap py-2 sm:flex-wrap-reverse hover:shadow-lg1 shadow-lg rounded">
                <div className=" lg:w-12/12 " >
                        <img
                        alt="..."
                        className=" font-extrabold w-77 lg:ml-1 p-2 "
              src={require("assets/img/ser-4.png").default}/>  
                  </div>
                  <div className=" lg:w-8/12 pl-4 sm:pt-52 text-white  sm:py-84">
                  Our rescue team will analysis the damages and quote the charges, 24/7 Rescue Link™ service 
                  charges will add automatically, according to customer’s packages subscribtion in e-quatation form.
                    </div>
                  </div>

                </div>

                <div className="w-full lg:w-6/12 lg:pl-22 sm:pt-52 sm:py-84">
                  
                <div className="flex  hover:shadow-lg1 shadow-lg rounded lg:flex-wrap py-2 pt-6 sm:flex-wrap-reverse">
                <div className=" lg:w-12/12  " >
                        <img
                        alt="..."
                        className=" font-extrabold w-77 lg:ml-1 p-2 "
              src={require("assets/img/ser-5.png").default}/>  
                  </div>
                  <div className=" lg:w-8/12  pl-4 sm:pt-52 text-white  sm:py-84">
                  Our rescue team start repair the damages once customer accept the e-quatation.
                    </div>
                  </div>
                  <br></br>
                  <div className="flex hover:shadow-lg1 shadow-lg rounded lg:flex-wrap py-2 sm:flex-wrap-reverse">
                <div className=" lg:w-12/12  " >
                        <img
                        alt="..."
                        className=" font-extrabold w-77 lg:ml-1 p-2 "
              src={require("assets/img/ser-6.png").default}/>  
                  </div>
                  <div className=" lg:w-8/12  pl-4 sm:pt-52  text-white sm:py-84">
                 Customer will pay the charges by Digital transaction or Cash On Delivery (COD) according to credit term.
                    </div>
                  </div>
                  <br></br>
                  <div className="flex  hover:shadow-lg1  rounded shadow-lg lg:flex-wrap py-2 sm:flex-wrap-reverse">
                <div className=" lg:w-12/12  " >
                        <img
                        alt="..."
                        className=" font-extrabold w-77 lg:ml-1 p-2 "
              src={require("assets/img/ser-7.png").default}/>  
                  </div>
                  <div className=" lg:w-8/12  pl-4 sm:pt-52 text-white  sm:py-84">
                  All repairing data will stored at cloud for customer’s future reference.
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>*/}



      <section className=" text-center bg-white md:px-40 md:py-32 py-10">
        <h6 className="md:text-4xl text-2xl text-blueMagenta font-bold  ">
          " Travel anywhere, anytime without any worries with us ".
        </h6>
      </section>

      {/* OUR SERVICES*/}

      {/* <section className=" text-center  bg-neutral-100 ">

        <h2 className="font-extrabold ttt text-4xl text-blueWhale pt-20  pb-2">

          Our Services

        </h2>

        <h2 className="font-normal text-base text-black pb-2 pt-4">
          Choose and book a car service experience and get upto <br></br>50% discount with the
          goals or streamline operations in a particular area of the business, such as sales,<br></br> IT, finance, marketing,
          supply chain management, HR, operations, engineering, and security.
        </h2>
        </section>*/}

      {/* First Col */}


      {/*<div className="sm:px-12 px-20 py-10 flex flex-col md:flex-row md:flex-wrap justify-center bg-neutral-100">

        <div className="hover:-mt-4 mb-6 flex break-words  transition ease-out duration-150" style={{
          padding: "1%"
        }}>
          <div className="w-80  bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
            <img
              alt="..."
              className=" text-align:left  w-17 ml-auto mr-6 p-2 "
              src={require("assets/img/puncture.png").default}
            />
            <h2 className="text-lg ttt text-blueWhale  font-bold">
              Puncture Repair Assistance
            </h2>
            <p className="mt-2 text-blueGray-500 text-base font-semibold">Strategic planning is a must for every business, process
            </p>
            <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <a
                href={'/Strategic'}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </button>
          </div>
        </div>

        <div className="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
          padding: "1%"
        }}>
          <div className="w-80 bg-white p-8  hover:bg-lightBlue-200 hover:shadow-lg">
            <img
              alt="..."
              className="max-w-full w-17 ml-auto mr-6 p-2 "
              src={require("assets/img/flat.png").default}
            />
            <h2 className="text-lg text-blueWhale font-bold ttt">
              Flat Tyre Assistance
            </h2>
            <p className="mt-2 text-blueGray-500 text-base font-semibold">International expansion strategies are formal,
            </p>
            <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <a
                href={'/Inter'}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </button>
          </div>
        </div>

        <div className="hover:-mt-4 mb-6 flex break-words  transition ease-out duration-150" style={{
          padding: "1%"
        }}>
          <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
            <img
              alt="..."
              className="max-w-full w-17 ml-auto mr-6 p-2 "
              src={require("assets/img/cartow.png").default}
            />
            <h2 className="text-lg text-blueWhale  font-bold ttt">
              Car Towing Assistance
            </h2>
            <p className="mt-2 text-blueGray-500 text-base font-semibold">The three major roles in human resources</p>
            <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2  py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <a
                href={'/Hrmanage'}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </button>
          </div>
        </div>

        <div className="hover:-mt-4 mb-6 flex break-words  transition ease-out duration-150" style={{
          padding: "1%"
        }}>
          <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg ">
            <img
              alt="..."
              className=" max-w-full w-17 ml-auto mr-6 p-2 "
              src={require("assets/img/cartow.png").default}
            />
            <h2 className="text-lg text-blueWhale font-bold ttt">
              Bike Towing Assistance
            </h2>
            <p className="mt-2 text-blueGray-500 text-base font-semibold">Technology selection is a major criteria decision</p>
            <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <a
                href={'/Techno'}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </button>
          </div>
      </div>*/}

      {/* second Col*/}
      {/*<div className="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
          padding: "1%"
        }}>
          <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
            <img
              alt="..."
              className="max-w-full w-17 ml-auto mr-6 p-2 "
              src={require("assets/img/battery.png").default}
            />
            <h2 className="text-lg text-blueWhale  font-bold ttt">
              Battery Jumpstart
            </h2>
            <p className="mt-2 text-blueGray-500 text-base font-semibold">Marketing is the best way to reach the new era through </p>
            <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <a
                href={'/Market'}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </button>
          </div>
        </div>

        <div className="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
          padding: "1%"
        }}>
          <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
            <img
              alt="..."
              className="max-w-full w-17 ml-auto mr-6 p-2 "
              src={require("assets/img/fuel.png").default}
            />
            <h2 className="text-lg text-blueWhale font-bold ttt">
              Emergency Fuel Delivery
            </h2>
            <p className="mt-2 text-blueGray-500 text-base font-semibold">In a business context, operational efficiency is a</p>
            <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <a
                href={'/Operation'}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </button>
          </div>
        </div>

        <div className="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
          padding: "1%"
        }}>
          <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
            <img
              alt="..."
              className="max-w-full w-17 ml-auto mr-6 p-2"
              src={require("assets/img/service-1.png").default}
            />
            <h2 className="text-lg text-blueWhale font-bold ttt">
              Mechanical Repairs
            </h2>
            <p className="mt-2 text-blueGray-500 text-base font-semibold">ISO 9001 certification helps organizations to develop</p>
            <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <a
                href={'/Certificate'}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </button>
          </div>
        </div>

        <div className="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
          padding: "1%"
        }}>
          <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
            <img
              alt="..."
              className="max-w-full w-17 ml-auto mr-6 p-2"
              src={require("assets/img/cable.png").default}
            />
            <h2 className="text-lg text-blueWhale font-bold ttt">
              Cable Replacement
            </h2>
            <p className="mt-2 text-blueGray-500 text-base font-semibold">A trademark is a type of intellectual property</p>
            <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <a
                href={'/Trademark'}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </button>
          </div>
      </div>*/}

      {/* Third Col */}

      {/* <div className="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
          padding: "1%"
        }}>
          <div className="w-80 bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
            <img
              alt="..."
              className="max-w-full w-17 ml-auto mr-6 p-2"
              src={require("assets/img/cartow.png").default}
            />
            <h2 className="text-lg text-blueWhale font-extrabold ttt">
              Vehicle Starting Trouble
            </h2>
            <p className="mt-2 text-blueGray-500 text-base font-semibold">Financial management may be defined as the area</p>
            <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <a
                href={'/Finance'}>
                <i className="fa fa-angle-right " aria-hidden="true"></i>
              </a>
            </button>

          </div>
        </div>

        <div className="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
          padding: "1%"
        }}>
          <div className="w-80  bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
            <img
              alt="..."
              className="max-w-full w-17 ml-auto mr-6 p-2"
              src={require("assets/img/fuse.png").default}
            />
            <h2 className="text-lg text-blueWhale font-bold ttt">
              Electrical & Fuse Repairs
            </h2>
            <p className="mt-2 text-blueGray-500 text-base font-semibold">Electronic commerce is the buying and selling </p>
            <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <a
                href={'/Commerce'}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </button>

          </div>
        </div>

        <div className="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
          padding: "1%"
        }}>
          <div className="w-80  bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
            <img
              alt="..."
              className="max-w-full w-17 ml-auto mr-6 p-2"
              src={require("assets/img/key.png").default}
            />
            <h2 className="text-lg text-blueWhale font-bold ttt">
              Key Recovery Services
            </h2>
            <p className="mt-2 text-blueGray-500 text-base font-semibold">Electronic commerce is the buying and selling </p>
            <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <a
                href={'/Commerce'}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </button>

          </div>
        </div>


        <div className="hover:-mt-4 mb-6 flex break-words transition ease-out duration-150" style={{
          padding: "1%"
        }}>
          <div className="w-80  bg-white p-8 hover:bg-lightBlue-200 hover:shadow-lg">
            <img
              alt="..."
              className="max-w-full w-17 ml-auto mr-6 p-2"
              src={require("assets/img/Chain.png").default}
            />
            <h2 className="text-lg text-blueWhale font-bold ttt">
              Chain Related Repairs
            </h2>
            <p className="mt-2 text-blueGray-500 text-base font-semibold">Electronic commerce is the buying and selling </p>
            <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-2 py-0 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              <a
                href={'/Commerce'}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </button>

          </div>
        </div>
      </div>*/}





      {/* New Section*/}
      <section className="md:py-24 bg-blueMagenta py-6 ">
        <div className="container mx-auto ">
          <h6 className="text-center pb-4 text-3xl text-white font-extrabold">
            Why Choose Us
          </h6>

          <div className="mt-2 px-12 justify-center flex md:flex-row flex-col">
            <div class="hover:-mt-4 mb-6  flex break-words  transition ease-out duration-150" style={{ padding: "1%" }}>
              <div className="w-80 p-2">
                <img
                  alt="..."
                  className="  w-24 mx-auto"
                  src={require("assets/img/trading.png").default} />
                <h6 className="md:text-lg py-2 text-white font-bold ttt text-center">Transparency Trading <br></br>System</h6>
              </div>
            </div>


            <div class="hover:-mt-4 mb-6 flex break-words  transition ease-out duration-150" style={{ padding: "1%" }}>
              <div className="w-80 p-2 ">
                <img
                  alt="..."
                  className="  w-24 mx-auto"
                  src={require("assets/img/rescueservice.png").default} />
                <h6 className="md:text-lg py-2 text-white font-bold ttt text-center"> Provide 24/7 <br></br>Rescue Service</h6>
              </div>
            </div>


            <div class="hover:-mt-4 mb-6 flex break-words  transition ease-out duration-150" style={{ padding: "1%" }}>
              <div className="w-80 p-2 ">
                <img
                  alt="..."
                  className="  w-24 mx-auto"
                  src={require("assets/img/oneclick.png").default} />
                <h6 className="md:text-lg py-2  text-white font-bold ttt text-center">One click for all <br></br>solutions to customer</h6>
              </div>
            </div>
          </div>

          {/*Second Choose*/}

          <div className="mt-2  px-12 flex md:flex-row flex-col justify-center">
            <div class="hover:-mt-4 mb-6 flex break-words  transition ease-out duration-150" style={{ padding: "1%" }}>
              <div className="w-80 p-2">
                <img
                  alt="..."
                  className="  w-24 mx-auto"
                  src={require("assets/img/fixedprice.png").default} />
                <h6 className="md:text-lg py-2   text-white font-bold ttt text-center">Fixed price and <br></br>cost saving</h6>
              </div>
            </div>


            <div class="hover:-mt-4 mb-6 flex break-words  transition ease-out duration-150" style={{
              padding: "1%"
            }}>
              <div className="w-80  p-2 ">
                <img
                  alt="..."
                  className=" w-24 mx-auto"
                  src={require("assets/img/serviceprovider.png").default} />
                <h6 className="md:text-lg py-2 px-6  text-white font-bold ttt text-center">Certified and experienced service provider</h6>
              </div>
            </div>


            <div class="hover:-mt-4 mb-6 flex break-words  transition ease-out duration-150" style={{ padding: "1%" }}>
              <div className="w-80 p-2">
                <img
                  alt="..."
                  className="  w-24 mx-auto"
                  src={require("assets/img/creditterm.png").default} />
                <h6 className="md:text-lg py-2 px-6  text-white font-bold ttt text-center ">Provide credit term to selective customer</h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

    </>
  );
}
